// Initilises OTP sign in

import { createPasswordlessCode, consumePasswordlessCode } from 'supertokens-web-js/recipe/thirdpartypasswordless'

export const useAuthOtpSignIn = async (emailOrPhone: string) => {
  // Check if the input is an email address or a phone number
  const otpSent = useState('otpSent') // Re-usable otpSent state
  if (emailOrPhone.includes('@')) {
    // send email OTP
    otpSent.value = await sendEmailOTP(emailOrPhone)
  } else {
    // Validate phone number and add +44 if nessecary.
    const validatedPhone = validateAndFormatUKMobile(emailOrPhone)
    if (!validatedPhone) {
      alert('Your phone number or email looks invalid. Check and try again')
      return false
    }
    otpSent.value = await sendPhoneOTP(validatedPhone)
    console.log({ otpSent: otpSent.value })
  }
  if (otpSent.value) {
    return true
  } else {
    console.log(`otpSent.value: ${otpSent.value}`)
    return false
  }
}

// Handles OTP input
export const useAuthOtpHandleInput = async (otp: string) => {
  console.log({ otp })
  const otpLoginError = useState('otpLoginError')
  const otpLoginSuccess = useState('otpLoginSuccess')
  if (!otp) {
    otpLoginError.value = 'Please enter the 6-digit code we sent you'
    return alert('Please enter a valid OTP')
  }
  try {
    // Call back-end to verify OTP
    const response = await consumePasswordlessCode({
      userInputCode: `${otp}`
    })

    console.log({ otpHandleInputResponse: response })

    if (response.status === 'OK') {
      if (response.createdNewUser) {
        console.log('New account creation and login success')
        // user sign up success
        otpLoginSuccess.value = 'Your account has been created'
        return navigateTo('/account/created')
      } else {
        // user sign in success
        console.log('Existing account login success')
        otpLoginSuccess.value = 'You are now logged in'
        return navigateTo('/account')
      }
      // window.location.assign('/home')
    } else if (response.status === 'INCORRECT_USER_INPUT_CODE_ERROR') {
      // the user entered an invalid OTP
      // window.alert('Wrong OTP! Please try again. Number of attempts left: ' + (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount))
      otpLoginError.value = 'Wrong OTP! Please try again. Number of attempts left: ' + (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount)
    } else if (response.status === 'EXPIRED_USER_INPUT_CODE_ERROR') {
      // it can come here if the entered OTP was correct, but has expired because
      // it was generated too long ago.
      // window.alert('Old OTP entered. Please regenerate a new one and try again')
      otpLoginError.value = 'Your code expired. Please regenerate a new one and try again'
    } else {
      // this can happen if the user tried an incorrect OTP too many times.
      // window.alert('Login failed. Please try again')
      otpLoginError.value = 'Something went wrong. Please try again'
      return navigateTo('/login')
    }
  } catch (err: any) {
    if (err.isSuperTokensGeneralError === true) {
      // this may be a custom error message sent from the API by you.
      alert(err.message)
      console.error({ error: err.message })
    } else {
      console.error({ error: err.message })
      alert('An unknown error occurred')
    }
  }
}

// Re-send OTP
export const useAuthOtpResend = () => {
  return alert('Re-send not yet available')
}

const sendEmailOTP = async (email: string) => {
  try {
    const response = await createPasswordlessCode({
      email
    })
    if (response.status !== 'OK') {
      alert('Something went wrong requesting your OTP.')
    }
    return response
  } catch (err: any) {
    if (err.isSuperTokensGeneralError === true) {
      // this may be a custom error message sent from the API by you,
      // or if the input email / phone number is not valid.
      window.alert(err.message)
    } else {
      window.alert('Oops! Something went wrong.')
    }
  }
}

const sendPhoneOTP = async (validatedPhone: string) => {
  try {
    const response = await createPasswordlessCode({
      phoneNumber: validatedPhone
    })
    if (response.status !== 'OK') {
      alert('Something went wrong requesting your OTP.')
    }

    return response
  } catch (err: any) {
    if (err.isSuperTokensGeneralError === true) {
      // this may be a custom error message sent from the API by you,
      // or if the input email / phone number is not valid.
      window.alert(err.message)
    } else {
      window.alert('Oops! Something went wrong.')
    }
  }
}

/**
 * Validates and formats a given UK mobile phone number.
 *
 * @param phoneNumber - The phone number to validate and format.
 * @returns The formatted phone number, prefixed with +44, or an empty string if invalid.
 */
const validateAndFormatUKMobile = (phoneNumber: string): string => {
  console.log(`Testing ${phoneNumber} validity`)
  const mobileRegex = /^(07)[\d]{9}$/ // UK mobile numbers start with 07 and have a total of 11 digits including the leading zero
  if (mobileRegex.test(phoneNumber)) { // If the phone number matches a UK mobile number
    // If the phone number is already prefixed with +44
    if (phoneNumber.substring(0, 3) === '+44') {
      console.log(`${phoneNumber} is already valid`)
      return phoneNumber
    } else {
      // Prefix with +44 and remove leading zero
      console.log(`${phoneNumber} needs +44 prefix`)
      return `+44${phoneNumber.substring(1)}`
    }
  }
  return '' // Return empty string if phone number is not a valid UK mobile number
}
